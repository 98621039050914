import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import { toRem, breakpoints } from "utils/mixins";
import { green, red, white } from "./colors";
import { container, ContainerSize } from "./sizes";
import { p, h1, h2, h3, h4 } from "./typography";

export const GlobalStyles = createGlobalStyle`
  ${normalize}
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background: ${white.default};
    cursor: default;
    overflow-x: hidden;
    @media (min-width: 1079px) {
      overflow-x: unset
    }
  }

  body {
    overflow-x: hidden;
    font-style: normal;
    font-family: "Gotham", sans-serif;
    font-weight: 300;
    font-size: ${toRem(20)};
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    color: black;
    -moz-osx-font-smoothing: grayscale;

    &.noover {
      overflow: hidden;
    }
  }

  b {
    font-weight: bold;
  }

  .main {
    padding-top: 68px;
  }

  .container {
    width: 100%;
    max-width: ${container.small};
    margin: 0 auto;
    position: relative;
    padding-left: ${toRem(20)};
    padding-right: ${toRem(20)};

    &--small {
      max-width: ${container.small};
    }

    &--center {
      text-align: center;
    }

    &--800 {
      max-width: ${toRem(800)};
      margin: ${toRem(12)} auto;
    }

    &--xsmall {
      max-width: ${container.xsmall};
    }

  }
  
  .section {
    //min-height: 100vh;
    &-conce {
      margin-bottom: 6rem;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      &-thumb {
        width: 100%;
        margin: 5% 0 0 0;
        min-height: 300px;
        &-image {
          position: relative;
          display: flex;
          align-items: center;
          height: calc(100% - 58px);
            &::before{
              content: "";
              position: absolute;
              z-index:1;
              background-color: rgba(0, 0, 0, 0.5);
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
           
          }
          .gatsby-image-wrapper{
            position: absolute!important;
            width: 100%;
            height: 100%;
          }
          
        }

        &-brands {
          z-index:1;
          position: relative;
          //top: 50%;
          width: 100%;
          padding: 20px;
          //transform: translateY(-50%);
          //left: 0;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
         
          img {
            margin: 5px;
            object-fit: contain !important;
            height: 40px ;
            width: 40px ;
          }
        }

        &-cta {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
          color: ${white.default};
          background-color: ${red.default};

          p {
            text-transform: uppercase;
            margin-bottom: 0;
            margin-top: 0;
            font-weight: bold;
            font-size: 30px;
          }
          a {
            min-width: 131px;
          }
          span{
            font-weight: normal;
            font-size: 50%;
          }

          img {
            margin-left: ${toRem(28)};
            display: inline-block;
            width: ${toRem(10)};
          }
        }

      }
    }

    h2 {
      margin: 0 0 80px;
      &.pad-top {
        padding-top: 80px;
      }
    }

    &--unlimited {
      .section-banner {
        max-height: 500px;
        min-height: 500px;

        .gatsby-image-wrapper {
          max-height: 500px !important;
          min-height: 500px !important;

        }
      }
    }

    &-banner {
      /* align-items: center; */
      width: 100%;
      position: relative;
      min-height: 400px;
      max-height: 40vh;
      h2 {
        margin:0;
      }
      &-brands {
        position: absolute;
        max-width: ${ContainerSize};
        left: 50%;
        top: 25px;
        width: 100%;
        height: calc(100% - 50px);
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        img {
          margin: 10px 15px;
          object-fit: contain !important;
          height: 100px !important;
          width: 100px !important;
        }
      }

      .gatsby-image-wrapper {
        width: 100%;
        min-height: 400px;
        max-height: 40vh!important;
        object-fit: cover;
      }

      h2 {
        ${h1};
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: ${white.default};
      }
    }

    &-content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      margin: 20px auto;

      p{
        width: 100%;
      }

      h4{
        text-decoration: underline;
        font-weight: bold;
      }

      &--bikes {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      &--columns {
        align-items: stretch;
        flex-direction: row;
        justify-content: space-between;
      }

      &-icon {
        height: 100px;
        width: 100px;
        margin: 20px;

        img {
          object-fit: contain !important;
        }
      }

      &-menu {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px 0;

        li {
          position: relative;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 24px;
          line-height: 26px;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: ${green.default};
            transform: scaleX(0);
            transition: 400ms;
          }

          &.active {
            &::after {
              transform: scaleX(1)
            }
          }
        }
      }

      &-texts {
        display: flex;
        align-items: stretch;
        position: relative;
        &-reference {
          opacity: 0;
          pointer-events: none;
        }
      }

      &-text {
        position: absolute;
        //height: 335px; 
        width: 100%; 
        h4{
          font-size: 20px;
          line-height: 1;
        } 
        p, h4 {
          text-align: center;
        }
        
        @media ${breakpoints.laptop} { 
         // height: 250px;  
        }
        &--hidden {
          display: none;
        }
      }

      /* 
            p {
              margin: 20px 0 40px;
            } */

      &-column {
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-logo {
          width: 100px;
          height: 100px;
          padding: 0;
          margin: 15px;
          object-fit: contain !important;
        }

        &-photo {
          width: auto;
          min-width: 300px;
          height: auto;
          padding: 0;
          margin-bottom: 15px;
          img {
            object-fit: contain !important;
          }
        }

        &-text {
          flex: 1;
          height: 100%;
        }
      }
    }

    &-form {
      width: 100%;

      &-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &-field {
        width: 100%;

        
        .error-message {
            margin: 0;
            font-size: 12px!important;
            color: ${red.default};
            text-align: left;
          }

        &--full {
          width: 100%;
        }

        &-up {
          display: flex;
          align-items: center;
          justify-content: space-between;

        }

        label, p {
          display: block;
          font-size: 18px;
          text-transform: uppercase;
        }

        input {
          ${p}
          width: 100%;
          border: none;
          outline: none;
          padding: 15px 10px;
          background-color: ${white.dark}
        }

        textarea {
          ${p}
          width: 100%;
          border: none;
          outline: none;
          padding: 15px 5px;
          background-color: ${white.dark}
        }
      }

      span {
        font-size: 12px;
      }

      &-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

     @media ${breakpoints.tabletSpec} {
      &-form {
        &-field {
          width: calc(50% - 10px);

          &--full {
            width: 100%;
          }
        }

      }

      &-content {
        &-menu {
          li {
            font-size: 30px;
            line-height: 34px;

            &::after {
              height: 4px;
            }
          }
        }

        &-icon {
          height: 150px;
          width: 150px;
          margin: 30px;
        }

        &-column {
          width: 33%;
        }
      }

      &-banner {
        max-height: 50vh;

        .gatsby-image-wrapper {
          max-height: 50vh !important;
        }
      }

      &-conce {

        justify-content: center;

        &-thumb {
          &-brands {
            img {
              margin: 10px;
              height: 60px;
              width: 60px;
            }
          }
        }
      }
    } 
  }

  .conce-intro{
    &-brands {
        width: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom:10px;
        &--sub{
          border-top:1px solid black;
          padding-top:10px;
        }
        img {
          margin: 10px 40px;
          object-fit: contain !important;
          height: 140px !important;
          width: 140px !important;
        }
      }
      margin-bottom: 60px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 400;
  }

  h1, .h1 {
    ${h1}
    color: black;
    text-align: center;
    text-transform: uppercase;
    margin: ${toRem(12)};
    transition: 250ms;
  }

  h2 {
    ${h2}
    font-weight: bold;
    color: black;
    text-align: center;
    text-transform: uppercase;
    margin: ${toRem(12)};
    transition: 250ms;
  }

  h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  p {
    ${p}
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 250ms;
  }

  button {
    appearance: none;
    display: inline-block;
    border-radius: 0;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
  }

  address {
    font-style: normal;
  }

  p {
    margin-bottom: 1em;
  }

  .show {
    display: block;
  }

  .hidden {
    display: none;
  }

  .hide__mobile {
    @media (max-width: 1079px) {
      display: none;
    }
  }

  .show__mobile {
    display: none;
    @media (max-width: 1079px) {
      display: block;
    }
  }

  .abs_hide {
    position: absolute;
    left: -5000px;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .underline {
    text-decoration: underline;
    padding-right: ${toRem(50)};
    padding-top: ${toRem(20)};
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: white;
    font-weight: bold;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: white;
    font-weight: bold;
  }

  :-ms-input-placeholder { /* IE 10+ */
    color: white;
    font-weight: bold;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: white;
    font-weight: bold;
  }

  .LoaderBalls {
    width: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__item {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: ${green.default};

      &:nth-child(1) {
        animation: bouncing 0.4s alternate infinite
          cubic-bezier(0.6, 0.05, 0.15, 0.95);
      }

      &:nth-child(2) {
        animation: bouncing 0.4s alternate infinite
          cubic-bezier(0.6, 0.05, 0.15, 0.95);
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation: bouncing 0.4s alternate infinite
          cubic-bezier(0.6, 0.05, 0.15, 0.95);
        animation-delay: 0.4s;
      }
    }
  }

  @keyframes bouncing {
    0% {
      transform: translate3d(0, 5px, 0) scale(1);
    }

    100% {
      transform: translate3d(0, -20px, 0) scale(1);
    }
  }

  @media ${breakpoints.laptop} {
    .main {
      padding-top: 144px;
    }

    .section {
      &-conce {
        &-thumb {
          width: 48%;
          margin: 1%;

          &-brands {
            img {
              margin: 10px;
              height: 60px;
              width: 60px;
            }
          }
        }
      }
    }
  }
`;
