import styled from "styled-components";

import { white, green } from "theme/colors";
import { breakpoints, toRem } from "utils/mixins";

export const OpenButton = styled.button`
  z-index: 2;
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 50px;

  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
  }

  @media ${breakpoints.laptop} {
    width: 70px;
    height: 70px;
    img {
      width: 70px;
      height: 70px;
    }
  }
`;

export const BannerContainer = styled.div`
  position: fixed;
  z-index: 150;
  bottom: 450px;
  right: 0;

  .success-message {
    max-height: 0;
    margin: 0;
    overflow: hidden;

    &--show {
      text-align: center;
      color: green;
      font-size: 15px;
      max-height: unset;
      margin: auto;
    }
  }

  .media-wrapper {
    position: absolute;
    top: calc(100% - 30px);
    right: calc(100% - 30px);

    padding: ${toRem(20)};
    z-index: 1;

    width: ${toRem(320)};
    background: #ffffff;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.44);
    border-radius: ${toRem(11)};
    transition: all 0.22s ease-out;
    transform-origin: 100% 100%;

    .icons {
      display: flex;
      justify-content: space-between;
      width: 100%;

      img {
        padding: 5px;
        width: 100%;
      }
    }

    h4 {
      font-size: ${toRem(18)};
      padding-top: ${toRem(6)};
      font-weight: bold;
    }

    .form {
      text-align: center;
      padding: ${toRem(10)};
      margin-top: ${toRem(8)};
      border-radius: ${toRem(19)};
      background-color: #d1d3d4;

      input[type="text"] {
        text-align: center;
        padding: ${toRem(7)};
        width: 100%;
        height: ${toRem(34)};
        background-color: ${white};
        appearance: none;
        border: none;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font-size: ${toRem(14)};
          letter-spacing: ${toRem(0.8)};
          color: #d1d3d4;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          font-size: ${toRem(14)};
          letter-spacing: ${toRem(0.8)};
          color: #d1d3d4;
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          font-size: ${toRem(14)};
          letter-spacing: ${toRem(0.8)};
          color: #d1d3d4;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          font-size: ${toRem(14)};
          letter-spacing: ${toRem(0.8)};
          color: #d1d3d4;
        }
      }

      h4 {
        font-size: ${toRem(21)};
        padding-top: ${toRem(8)};
        font-weight: bold;
      }

      button {
        margin: 0 !important;
      }

      .media-checkbox {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        position: relative;
        margin: 12px 0;
        cursor: pointer;
        user-select: none;
        p {
          margin-bottom: 0;
          padding-left: 10px;
          font-size: ${toRem(11)};
          line-height: ${toRem(13)};
          letter-spacing: ${toRem(0.55)};
        }
        a {
          font-size: ${toRem(11)};
          line-height: ${toRem(13)};
          text-decoration: underline;
        }

        &--red {
          span {
            color: red;
          }
        }

        input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
      }

      .checkmark {
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #fff;
        position: relative;
        &::after {
          opacity: 0;
          content: "✓";
          left: 50%;
          transform: translateX(-50%);
          top: 0;
          line-height: 25px;
          font-size: 25px;
          color: ${green.default};
          position: absolute;
        }
      }

      .media-checkbox {
        &:hover input ~ .checkmark {
          background-color: #fff;
        }
      }

      .media-checkbox input {
        &:checked ~ .checkmark {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  @media ${breakpoints.laptop} {
    .media-checkbox {
      padding: 10px 0;
    }

    .media-wrapper {
      width: ${toRem(385)};
    }

    h4 {
      font-size: ${toRem(21)};
      padding-top: ${toRem(8)};
    }

    .form {
      padding: ${toRem(15)};
      margin-top: ${toRem(10)};
    }

    input[type="email"] {
      padding: ${toRem(10)};
      height: ${toRem(46)};

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: ${toRem(16)};
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: ${toRem(16)};
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: ${toRem(16)};
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: ${toRem(16)};
      }
    }
  }
  /* .close-button {
    cursor: pointer;
    position: absolute;
    top: ${toRem(-18)};
    right: ${toRem(-18)};
    width: ${toRem(36)};
    height: ${toRem(36)};
  
} */
`;
