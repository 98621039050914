import React from "react";

import { FooterContainer } from "./Footer.styles";
import Link from "gatsby-link";

const Footer = (props) => {
  return (
    <FooterContainer>
      <div>
        <p>Moto Spazio Portugal © Conceição Machado LDA • 2021</p>
      </div>

      <ul>
        <li>
          <Link to={"/privacy-policy/"}>Política de Privacidade</Link>
        </li>
      </ul>
    </FooterContainer>
  );
};

export default Footer;
