import React from "react";
import { Helmet } from "react-helmet";

const CreateHeadDefault = (props) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: "pt"
      }}
    >
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="Motospazio, Vespa, Moto Guzzi, Piaggio, Aprilia, Motas, motas usadas, concessionarios"
      />

      <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
      <meta name="msapplication-TileColor" content="#fffdf7" />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />

      <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      {/* <link as="font" href="/fonts/Sneak-Medium.woff2" rel="preload" crossorigin="anonymous"/>  */}
      <link rel="stylesheet" href="/fonts/stylesheet.css" />
      <link
        rel="stylesheet"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.5.1/gsap.min.js"
        integrity="sha512-IQLehpLoVS4fNzl7IfH8Iowfm5+RiMGtHykgZJl9AWMgqx0AmJ6cRWcB+GaGVtIsnC4voMfm8f2vwtY+6oPjpQ=="
        crossorigin="anonymous"
      ></script>
    </Helmet>
  );
};
export default CreateHeadDefault;
