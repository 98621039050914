import React from "react";
import { Helmet } from "react-helmet";

const CreateHead = (props) => {
  //PROPS
  const { title, image, description, url } = props;
  //PROPS

  //DEFS
  // !!TODO : base info + lang pack + fonts
  const headInfo = {
    title: title ? title : "Moto Spazio Portugal ",
    description: description
      ? description
      : "Moto Spazio é a marca criada para identificar os pontos de venda Piaggio, Vespa, Aprilia, Moto Guzzi e outros em Portugal. Somos também os representantes da marca de equipamento Merlin Motorcycle Gear. Estamos presentes em Lisboa, Porto, Aveiro, Évora e Viseu. Temos a solução ideal de mobilidade para todas as situações: desde uma scooter elétrica para uma utilização urbana, a uma moto de alta cilindrada capaz de fazer longas viagens com o máximo conforto.",
    url: url ? process.env.GATSBY_SITE + url : process.env.GATSBY_SITE,
    image: image ? image : "/images/share.jpg"
  };
  //DEFS

  return (
    <Helmet
      htmlAttributes={{
        lang: "pt"
      }}
    >
      <title>{headInfo.title}</title>
      <meta name="description" content={headInfo.description} />

      <meta itemprop="name" content={headInfo.title} />
      <meta itemprop="description" content={headInfo.description} />
      <meta itemprop="image" content={headInfo.image} />

      <meta name="title" content={headInfo.title} />

      <meta property="og:url" content={headInfo.url} />
      <meta property="og:title" content={headInfo.title} />
      <meta property="og:description" content={headInfo.description} />
      <link rel="image_src" href={headInfo.image}></link>
      <meta property="og:image" content={headInfo.image} />

      <meta property="og:site_name" content={headInfo.title} />

      <meta name="twitter:title" content={headInfo.title} />
      <meta name="twitter:description" content={headInfo.description} />
      <meta name="twitter:image" content={headInfo.image} />
      <meta name="twitter:image:alt" content={headInfo.title} />

      {props.children}
    </Helmet>
  );
};
export default CreateHead;
