module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/builds/Motospazio/moto-prod/src/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://admin.motospazio.pt/graphql","verbose":true,"schema":{"queryDepth":6,"typePrefix":"Wp","perPage":100,"timeout":500000000,"requestConcurrency":5,"previewRequestConcurrency":2,"circularQueryLimit":5},"develop":{"nodeUpdateInterval":50000,"hardCacheMediaFiles":false,"hardCacheData":false},"production":{"hardCacheMediaFiles":false,"allow404Images":false},"type":{"MediaItem":{"localFile":{"requestConcurrency":25,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
