import React, { useState } from "react";
import { black, white } from "theme/colors";

import { useForm } from "react-hook-form";
import axios from "axios";
import gsap from "gsap";
import Link from "gatsby-link";

import { BannerContainer, OpenButton } from "./MediaPopup.styles";
import Button from "../button";

const tl = gsap.timeline();

const MediaPopup = () => {
  const { register, errors, handleSubmit } = useForm();
  const [contact, setContact] = useState({
    isCheck: false
  });

  const clearForm = () => {
    document.getElementById("mediaForm").reset();
  };

  const onSubmit = async (e) => {
    sendMessage();
  };

  const sendMessage = () => {
    document
      .querySelector(".media-checkbox")
      .classList.remove("media-checkbox--red");

    let isChecked = document.querySelector("#media-checkbox-input").checked;

    if (isChecked) {
      var contactsFormData = new FormData();
      
      contactsFormData.append(
        "mediaEmail",
        document.querySelector("#mediaEmail").value
      );

      var config = {
        method: "post",
        url: `https://admin.motospazio.pt/wp-json/contact-form-7/v1/contact-forms/365/feedback`,
        headers: {
          "Content-Type": "application/json"
        },
        data: contactsFormData
      };

      axios(config)
        .then(function (response) {
          if (response.data.status === "mail_sent") {
            document
              .querySelector(".success-message")
              .classList.add("success-message--show");
            // setResponse({
            //   type: "success",
            //   message:
            //     "Subscrição efetuada com sucesso."
            // });
            clearForm();
            setContact({
              mediaEmail: ""
            });
          } else {
            // setResponse({
            //   type: "error",
            //   message:
            //     "Algo de errado aconteceu. Tenta outra vez por favor. Senão manda-nos diretamente um email."
            // });
          }
        })
        .catch(function (error) {
          // setResponse({
          //   type: "error",
          //   message:
          //     "Algo de errado aconteceu. Tenta outra vez por favor. Senão manda-nos diretamente um email."
          // });
        });
    } else {
      document
        .querySelector(".media-checkbox")
        .classList.add("media-checkbox--red");
    }
  };
  //REF
  const [open, setOpen] = useState(true);

  const openMenu = () => {
    setOpen(true);
    tl.to(".media-container", {
      duration: 0.4,
      bottom: 450
    })
      .to(".media-wrapper", {
        duration: 0.4,
        delay: -0.4,
        opacity: 1,
        pointerEvents: "auto"
      })
      .to(".media-open-btn", {
        duration: 0.22,
        delay: -0.4,
        opacity: 0
      })
      .to(".media-close-btn", {
        duration: 0.22,
        delay: -0.3,
        opacity: 1
      });
  };

  const closeMenu = () => {
    setOpen(false);
    tl.to(".media-container", {
      duration: 0.4,
      bottom: 20
    })
      .to(".media-wrapper", {
        duration: 0.4,
        delay: -0.4,
        opacity: 0,
        pointerEvents: "none"
      })
      .to(".media-close-btn", {
        duration: 0.22,
        delay: -0.4,
        opacity: 0
      })
      .to(".media-open-btn", {
        duration: 0.22,
        delay: -0.3,
        opacity: 1
      });
  };

  return (
    <BannerContainer className="media-container">
      <OpenButton
        className="media-button"
        onClick={() => (open ? closeMenu() : openMenu())}
      >
        <img
          src="/images/open-button.png"
          alt="abrir"
          className="media-open-btn"
        />

        <img
          src="/images/close-button.png"
          alt="fechar"
          className="media-close-btn"
        />
      </OpenButton>
      <div className="media-wrapper">
        <h4>SEGUE-NOS NAS REDES SOCIAIS</h4>
        <div className="icons">
          <a
            href="https://www.instagram.com/motospaziopt/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/instagram.svg" alt="instagram" />
          </a>
          <a
            href="https://www.facebook.com/motospaziopt"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/facebook.svg" alt="facebook" />
          </a>
        </div>
        <div className="form">
          <form id="mediaForm" onSubmit={handleSubmit(onSubmit)}>
            <h4>SUBSCREVER NEWSLETTER</h4>

            <div className="row">
              <div className="field">
                <div className="field-up">
                  <p className="success-message">
                    Subscrição efetuada com sucesso.
                  </p>
                  {errors.mediaEmail?.type === "required" && (
                    <p>Precisamos do seu e-mail.</p>
                  )}
                  {errors.mediaEmail?.type === "pattern" && (
                    <p>Precisamos de um e-mail válido.</p>
                  )}
                </div>
                <label htmlFor="mediaEmail">
                  <input
                    placeholder="O SEU E-MAIL"
                    id="mediaEmail"
                    name="mediaEmail"
                    type="text"
                    ref={register({
                      required: true,
                      pattern: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/
                    })}
                  />
                </label>
              </div>
            </div>
            <label className="media-checkbox">
              <input
                type="checkbox"
                id="media-checkbox-input"
                onClick={() =>
                  setContact({ ...contact, isCheck: !contact.isCheck })
                }
              />
              <span className="checkmark" />
              <p>
                Li e aceito a{" "}
                <Link to="/privacy-policy/">Política de Proteção de Dados</Link>
              </p>
            </label>
            <Button
              isDisabled={!contact.isCheck}
              text="Enviar"
              backgroundColor={black.default}
              color={white.default}
              minWidth
            />
          </form>
        </div>
      </div>
    </BannerContainer>
  );
};
export default MediaPopup;
